import React from "react";
import HeaderContainer from "./Components/HeaderContainer/HeaderContainer";
import SiteContainer from "./Components/SiteContainer/SiteContainer";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";

function App() {
  console.log('hellocentral')
  return (
    <div className="app">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <SiteContainer>
          <HeaderContainer />
        </SiteContainer>
      </BrowserRouter>
    </div>
  );
}

export default App;
